import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  components: {
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: 'secondary.600',
        },
        emptyTrack: {
          bg: 'main.900',
        },
      },
    },
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    main: {
      100: '#E3E3E3',
      200: '#D8D8D8',
      300: '#A7998F',
      400: '#A79C8D',
      500: '#595757',
      800: '#2B2B2B',
      900: '#000000',
    },
    secondary: {
      600: '#f7313d',
      700: '#D1514C',
    },
    disabled: {
      100: '#919090',
      200: '#3A3836',
      300: '#202421',
    },
  },
  styles: {
    global: {
      body: {
        background: 'main.100',
      },
    },
  },
  fonts: {
    heading: 'Moon',
    body: 'Moon',
  },
})
