import React, { useState } from 'react'
import { Box, Center, Flex, Image } from '@chakra-ui/react'
import { lands as poolsInfo } from 'data/lands'
import { useLands } from 'hooks/useLands'
import { useWallet } from 'providers/WalletProvider'
import {
  Banner,
  Countdown,
  ErrorMessage,
  Exchanges,
  LandCard,
  Loader,
  SuccessModal,
} from 'components'
import './styles.css'
import { PAGE_MAX_WIDTH } from 'data/config'

const StakingForLands = () => {
  const { account, chainId } = useWallet()
  const { pools, isLoading, reload, stakeTokens, withdrawTokens } = useLands({
    poolsInfo,
    account,
    chainId,
  })

  const rewards = [
    {
      name: 'Nebula Spacesuit',
      type: 'Rare',
    },
    {
      name: 'Zenith Spacesuit',
      type: 'Epic',
    },
    {
      name: 'Quasar Spacesuit',
      type: 'Legendary',
    },
  ]

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const handleStake = () => {
    reload()
    setIsSuccessModalOpen(true)
  }

  return (
    <>
      <Image
        src="/img/moon/moon.png"
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        w="100%"
        minW={{ base: '1400px', lg: 0 }}
        maxW={PAGE_MAX_WIDTH}
        objectFit="cover"
        zIndex={-1}
      />

      <Banner />
      <Flex direction="column">
        <Flex direction="column" justifyContent="center" alignItems="center">
          {isLoading && (
            <Box py={32}>
              <Loader text="Loading" />
            </Box>
          )}
          {!isLoading && pools && (
            <Flex justifyContent="center" flexDirection={{ base: 'column', xl: 'row' }}>
              {pools.map((landPool, index) => (
                <Box className={`land-card-${index + 1}`}>
                  <Image src={`/img/landCard/card${index + 1}.png`} />
                  <LandCard
                    stakeAmount={landPool.stakeAmount}
                    reward={rewards[index].name}
                    rewardType={rewards[index].type}
                    stakeTokens={stakeTokens}
                    withdrawTokens={withdrawTokens}
                    onStake={handleStake}
                    landPool={landPool}
                  />
                </Box>
              ))}
            </Flex>
          )}
          {!isLoading && !pools && (
            <Center py={36} fontSize="xl">
              <ErrorMessage message="Something went wrong. Please try again later." />
            </Center>
          )}
        </Flex>
        <Countdown />
        <Exchanges />
      </Flex>
      <SuccessModal isOpen={isSuccessModalOpen} onClose={() => setIsSuccessModalOpen(false)} />
    </>
  )
}

export default StakingForLands
