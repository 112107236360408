import React from 'react'
import { Flex, Image } from '@chakra-ui/react'
import Button from 'components/Buttons/Button/Button'

const Exchanges = () => {
  const exchanges = [
    {
      imgPath: '/img/exchanges/mexc.png',
      text: 'Get $2MOON',
      link: 'https://www.mexc.com/exchange/2MOON_USDT',
    },
    {
      imgPath: '/img/moon/logo.png',
      text: 'Visit marketplace',
      link: 'https://market.moon.ws',
    },
  ]

  const handleButtonClick = link => {
    // Open the link in a new tab
    window.open(link, '_blank')
  }

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      justify="center"
      align={{ base: 'center', md: 'stretch' }}
      mb={{ base: 10, lg: 20 }}
    >
      {exchanges.map(exchange => (
        <Flex
          direction="column"
          w={60}
          h={40}
          m={2}
          pt={6}
          pb={4}
          bgColor="main.800"
          justify="space-between"
          align="center"
        >
          <Image w="90%" src={exchange.imgPath} objectFit="contain" />
          <Button variant={'primary'} w="90%" onClick={() => handleButtonClick(exchange.link)}>
            {exchange.text}
          </Button>
        </Flex>
      ))}
    </Flex>
  )
}

export default Exchanges
