import React from 'react'
import { Spinner, Box } from '@chakra-ui/react'

type Props = {
  text?: string
  spinnerSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  fontSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
}

const Loader = ({ text, spinnerSize = 'xl', fontSize = 'lg' }: Props) => {
  return (
    <Box textAlign="center">
      <Spinner size={spinnerSize} />
      <Box fontSize={fontSize} mt={4}>
        {text}
      </Box>
    </Box>
  )
}

export default Loader
