import React, { ReactNode } from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  useDisclosure,
  Link,
  Flex,
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Item } from 'components/Navbar/types'

type Props = {
  children: ReactNode
  item: Item
}

const HoverMenu = ({ children, item }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <Box onMouseOver={onOpen} onMouseLeave={onClose}>
        <Link href={item.url} _hover={{ textDecoration: 'none', color: 'secondary.600' }}>
          <MenuButton as={Box}>
            {children} {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </MenuButton>
        </Link>
        <MenuList zIndex={4} bgColor="main.100" borderRadius="0" mt={-2}>
          <Flex direction="column">
            {item.children.map(child => {
              if (child.url) {
                return (
                  <Link
                    href={child.url}
                    bgColor="main.100"
                    color="main.900"
                    _hover={{ textDecoration: 'none', color: 'secondary.600' }}
                    display="inline-block"
                    key={child.name}
                  >
                    <MenuItem bgColor="main.100" fontSize="lg">
                      {child.name}
                    </MenuItem>
                  </Link>
                )
              } else {
                return (
                  <MenuItem
                    bgColor="main.100"
                    color="main.900"
                    _hover={{ textDecoration: 'none', color: 'secondary.600' }}
                    key={child.name}
                    fontSize="lg"
                  >
                    {child.name}
                  </MenuItem>
                )
              }
            })}
          </Flex>
        </MenuList>
      </Box>
    </Menu>
  )
}

export default HoverMenu
