export const getEllipsisText = (str: string, n = 6) => {
  if (str) {
    return `${str.substr(0, n)}...${str.substr(str.length - n, str.length)}`
  }
  return ''
}

export const getFormattedNumber = (num: number) => {
  return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
