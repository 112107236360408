import React from 'react'
import { Text, Image, Flex } from '@chakra-ui/react'
import { Button } from 'components'

type Props = {
  errorMessage: string | null
  closeModal: () => void
}

const ModalErrorBody = ({ errorMessage, closeModal }: Props) => (
  <Flex direction="column" align="center">
    <Image w={62} src="/img/modal/error.png" />
    <Text textTransform="uppercase" fontSize="2xl" color="main.100" my={4}>
      Whoops...!
    </Text>
    <Text color="main.100">{errorMessage}</Text>
    <Button variant="primary" onClick={closeModal} py={1} mt={8} borderRadius="50px" w="100%">
      Close
    </Button>
  </Flex>
)

export default ModalErrorBody
