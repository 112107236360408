import React from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import WalletProvider from 'providers/WalletProvider'
import { theme } from 'styles/theme'
import { Buffer } from 'buffer'
import App from './App'
import 'index.css'

import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { bsc } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectLegacyConnector } from 'wagmi/connectors/walletConnectLegacy'

const isProduction = process.env.REACT_APP_PRODUCTION !== 'false'

if (isProduction && window.location.protocol !== 'https:') {
  window.location.replace(
    `https:${window.location.href.substring(window.location.protocol.length)}`,
  )
}

window.Buffer = window.Buffer || Buffer

const { chains, publicClient, webSocketPublicClient } = configureChains([bsc], [publicProvider()])

const config = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectLegacyConnector({ options: {} }),
    new MetaMaskConnector({ chains }),
  ],
  publicClient,
  webSocketPublicClient,
})

ReactDOM.render(
  <React.StrictMode>
    <WagmiConfig config={config}>
      <ChakraProvider theme={theme}>
        <WalletProvider>
          <App />
        </WalletProvider>
      </ChakraProvider>
    </WagmiConfig>
  </React.StrictMode>,
  document.getElementById('root'),
)
