import React from 'react'
import { Flex, Progress, Text } from '@chakra-ui/react'
import { DEFAULT_LAND_CLOSING_DATE, DEFAULT_LAND_STARTING_DATE } from 'data/config'

import { useCountdown } from 'hooks/useCountdown'

const Countdown = () => {
  const [days, hours, minutes, timeLeftInMilliseconds] = useCountdown(DEFAULT_LAND_CLOSING_DATE)

  // Calculate the total duration of the countdown in milliseconds
  const totalDuration = DEFAULT_LAND_CLOSING_DATE - DEFAULT_LAND_STARTING_DATE

  return (
    <>
      <Flex direction="column" align="center" color="main.900" my="100px">
        <Text
          textTransform="uppercase"
          fontSize={{ base: '3xl', sm: '5xl' }}
          fontWeight="bold"
          letterSpacing="1px"
          mb={4}
          mx={12}
        >
          Time is running out
        </Text>
        <Text textTransform="uppercase" w={{ base: '80%', md: 600 }} fontSize="lg">
          {days} {days === 1 ? 'day' : 'days'} {hours} {hours === 1 ? 'hour' : 'hours'} {minutes}{' '}
          {minutes === 1 ? 'minute' : 'minutes'} remaining
        </Text>
        <Progress
          value={((totalDuration - timeLeftInMilliseconds) / totalDuration) * 100}
          w={{ base: '80%', md: 600 }}
          h="16px"
          borderRadius="xl"
          bg="main.900"
        />
      </Flex>
    </>
  )
}

export default Countdown
