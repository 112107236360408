import React, { ReactNode } from 'react'
import { Box, Flex, BoxProps } from '@chakra-ui/react'

interface Props extends BoxProps {
  children: ReactNode
  color?: string
  disabled?: boolean
  variant: string
  onClick?: () => void
}

const Button = ({ children, disabled, variant, onClick, ...rest }: Props) => {
  const variantStyles = {
    primary: {
      bgColor: 'secondary.600',
      bgGradient: '',
      border: '',
      borderColor: '',
      color: 'white',
    },
    disabled: {
      bgColor: 'disabled.200',
      bgGradient: '',
      border: '1px solid',
      borderColor: 'disabled.100',
      color: 'disabled.100',
    },
  }

  return (
    <Box
      onClick={onClick}
      textAlign="center"
      textTransform="uppercase"
      border={variantStyles[variant].border}
      borderColor={variantStyles[variant].borderColor}
      borderRadius="3xl"
      opacity={disabled ? 0.5 : undefined}
      bgColor={variantStyles[variant].bgColor}
      bgGradient={variantStyles[variant].bgGradient}
      color={variantStyles[variant].color}
      cursor="pointer"
      {...rest}
    >
      <Flex justifyContent="center" alignItems="center" h="100%" py={2}>
        {children}
      </Flex>
    </Box>
  )
}

export default Button
