import React from 'react'
import { chakra, Flex, Image, Text } from '@chakra-ui/react'

const Banner = () => {
  return (
    <Flex
      direction="column"
      pt={8}
      pb={14}
      justifyContent="space-evenly"
      mixBlendMode="normal"
      position="relative"
    >
      <Flex
        direction={{ base: 'column', xl: 'row' }}
        justifyContent="center"
        alignItems="center"
        mx={{ base: 8, xl: 0 }}
        zIndex="3"
      >
        <Flex direction="column" ml={8} textAlign="center" position="relative">
          <Text
            fontSize={{ base: 'lg', sm: 'xl' }}
            fontWeight="bold"
            textTransform="uppercase"
            color="main.100"
          >
            Stake $2MOON and be rewarded with
          </Text>
          <Text
            fontSize={{ base: '4xl', sm: '6xl' }}
            textTransform="uppercase"
            color="secondary.600"
            lineHeight={1}
            fontWeight="bold"
          >
            $277,850 worth of
          </Text>
          <Text
            fontSize={{ base: '3xl', sm: '5xl' }}
            fontWeight="bold"
            textTransform="uppercase"
            color="main.100"
            lineHeight={1}
          >
            Unique moon nft
          </Text>
          <Text fontSize={{ base: 'lg', sm: 'xl' }} fontWeight="bold" color="main.100">
            Campaign is limited to{' '}
            <chakra.span fontSize={{ base: 'xl', sm: '3xl' }}>650</chakra.span> participants!
          </Text>
          <Image
            src="/img/banner/spaceSuit.png"
            position="absolute"
            top="-55px"
            right="-325px"
            transform="translateX(-50%)"
            display={{ base: 'none', xl: 'block' }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Banner
