import { primaryToken } from 'data/primaryToken'
import erc20Abi from 'data/abi/erc20Token.json'
import { useEffect, useState } from 'react'
import { useWallet } from 'providers/WalletProvider'

type Props = {
  account: string | null
  spender: string
}

export const useTokenApproval = ({ account, spender }: Props) => {
  const [isApproved, setIsApproved] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { readContract, writeContract } = useWallet()

  const checkAllowance = async () => {
    if (!account) {
      setIsLoading(false)
      return
    }
    const result = await readContract({
      address: primaryToken.address,
      abi: erc20Abi,
      functionName: 'allowance',
      args: [account, spender],
    })
    setIsApproved(`${result}` !== '0')
    setIsLoading(false)
  }

  const approve = async () => {
    try {
      await writeContract({
        address: primaryToken.address,
        abi: erc20Abi,
        functionName: 'approve',
        args: [spender, '1157920892373161954235709850086879078'],
      })
      setIsApproved(true)
      return true
    } catch (ex) {
      console.error(ex)
      return false
    }
  }

  useEffect(() => {
    checkAllowance()
    // eslint-disable-next-line
  }, [account, spender])

  return { approve, isApproved, isLoading }
}
