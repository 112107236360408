import React from 'react'
import { Text, Box, chakra, Center } from '@chakra-ui/react'
import { getFormattedNumber } from 'utils/formatters'
import { Button, Loader } from 'components'

type Props = {
  amount: number
  tokenSymbol: string
  isApproved: boolean
  isLoading: boolean
  handleStakeClick: () => void
  handleApproveClick: () => void
}

const ModalStakeBody = ({
  amount,
  tokenSymbol,
  isApproved,
  isLoading,
  handleStakeClick,
  handleApproveClick,
}: Props) => (
  <Box>
    {!isLoading && !isApproved && (
      <>
        <Text color="main.100">
          Please approve the staking by clicking the button below and confirming in your wallet
          extension.
        </Text>
        <Text color="main.100" mt={8}>
          Amount:{' '}
          <chakra.span fontWeight="bold">
            {getFormattedNumber(amount)} {tokenSymbol}
          </chakra.span>
        </Text>
      </>
    )}
    {!isLoading && isApproved && <Text color="main.100">Press the button below to stake</Text>}
    <Box pt={6}>
      {isLoading && (
        <Center mt={2}>
          <Loader text="Waiting for transaction, it might take a while" />
        </Center>
      )}
      {!isLoading && isApproved && (
        <Button variant="primary" onClick={handleStakeClick} py={1} mt={28} borderRadius="50px">
          STAKE
        </Button>
      )}
      {!isLoading && !isApproved && (
        <Button variant="primary" onClick={handleApproveClick} py={1} borderRadius="50px">
          APPROVE
        </Button>
      )}
    </Box>
  </Box>
)

export default ModalStakeBody
