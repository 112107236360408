import { useEffect, useState } from 'react'
import erc20Abi from 'data/abi/erc20Token.json'
import { primaryToken } from 'data/primaryToken'
import { DEFAULT_CHAIN_ID } from 'data/config'
import { useWallet } from 'providers/WalletProvider'

type Props = {
  account: string | null
  chainId: string | null
}

export const useTokenBalance = ({ account, chainId }: Props) => {
  const [balance, setBalance] = useState<string>('0')
  const { readContract } = useWallet()

  const getBalance = async () => {
    if (!account) {
      return
    }
    const tokenBalance = await readContract({
      address: primaryToken.address,
      abi: erc20Abi,
      functionName: 'balanceOf',
      args: [account],
    })
    console.log('Token balance:', tokenBalance)
    setBalance(`${tokenBalance}`)
  }

  useEffect(() => {
    getBalance()
    // eslint-disable-next-line
  }, [account])

  return { balance }
}
