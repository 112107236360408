import React from 'react'
import { Image, Text, Flex, Link, chakra, Box } from '@chakra-ui/react'
import { PAGE_MAX_WIDTH } from 'data/config'
import { MenuData } from 'components/Navbar/types'

type Props = {
  menuData: MenuData | null
}

const Footer = ({ menuData }: Props) => {
  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <Image
        src="/img/footer/alien.png"
        position="absolute"
        bottom="0"
        left="50%"
        transform="translateX(-50%)"
        display={{ base: 'none', lg: 'block' }}
      />
      <Box
        w="700px"
        h="500px"
        position="absolute"
        bottom="0"
        left="50%"
        transform="translateX(-50%)"
        bgGradient="radial-gradient(50% 50% at 50.00% 70.00%, rgba(143, 163, 171, 0.46) 0%, rgba(40, 40, 40, 0.00) 100%)"
        display={{ base: 'none', lg: 'block' }}
      />
      <Flex
        bgColor="main.900"
        pt={8}
        pb={12}
        px={16}
        minH={410}
        color="main.100"
        maxW={PAGE_MAX_WIDTH}
        mx="auto"
      >
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          align={{ base: 'space-between', md: 'space-between' }}
          w="100%"
        >
          <Flex
            direction="column"
            justify="space-between"
            align={{ base: 'center', md: 'stretch' }}
          >
            <Image src="/img/moon/logo.png" w={60} />
            <Flex direction="column" textAlign={{ base: 'center', md: 'left' }} fontSize="lg">
              <Text pt={{ base: 4, md: 0 }} pb={1}>
                land@moon.ws
              </Text>
              <Text pt={1} pb={2}>
                Moon is part of <chakra.span borderBottom="1px solid">Solar Metaverse</chakra.span>
              </Text>
              <chakra.span display="inline-block">
                <Text
                  color="secondary.600"
                  onClick={handleBackToTop}
                  display="inline"
                  _hover={{ cursor: 'pointer' }}
                >
                  Back to top
                </Text>
              </chakra.span>
            </Flex>
          </Flex>
          <Flex
            direction="column"
            justify="space-between"
            w={{ base: '100%', md: '30%' }}
            my={{ base: 4, md: 0 }}
            zIndex={1}
          >
            <Flex justify="space-between" align={{ base: 'space-between', md: 'stretch' }}>
              <Flex direction="column" mr={4}>
                {menuData?.footer_menu_items.slice(0, -2).map(link => (
                  <Link
                    href={link.url}
                    target="_blank"
                    _hover={{ textDecoration: 'none', color: 'secondary.600' }}
                  >
                    <Text py={1} fontSize="lg">
                      {link.name}
                    </Text>
                  </Link>
                ))}
              </Flex>
              <Flex direction="column" mr={{ base: 0, md: 12 }}>
                {menuData?.social_links.map(link => (
                  <Link
                    href={link.url}
                    target="_blank"
                    _hover={{ textDecoration: 'none', color: 'secondary.600' }}
                  >
                    <Text py={1} fontSize="lg">
                      {link.name}
                    </Text>
                  </Link>
                ))}
              </Flex>
            </Flex>
            <Flex justify="space-between" mt={{ base: 4, md: 0 }}>
              {menuData?.footer_menu_items.slice(-2).map(link => (
                <Link
                  href={link.url}
                  target="_blank"
                  _hover={{ textDecoration: 'none', color: 'secondary.600' }}
                  mr={4}
                >
                  <Text py={1} fontSize="lg">
                    {link.name}
                  </Text>
                </Link>
              ))}
              <Text textAlign={{ base: 'right', md: 'left' }} fontSize="lg" mr={4}>
                All rights reserved &copy; MOON {new Date().getFullYear()}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default Footer
