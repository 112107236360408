import React, { useEffect } from 'react'
import { Box, Button, Image, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { useWallet } from 'providers/WalletProvider'
import { Button as CustomButton, Modal } from 'components'

export default function ConnectButton() {
  const { account, connect, disconnect } = useWallet()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleDisconnect = () => {
    disconnect()
    window.location.reload()
  }

  const handleConnect = async (type: string) => {
    connect(type)
  }

  const connectOptions = [
    { title: 'Metamask', type: 'injected', icon: '/img/metamask.svg' },
    { title: 'WalletConnect', type: 'walletconnect', icon: '/img/walletconnect.svg' },
  ]

  useEffect(() => {
    if (account) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  return (
    <>
      <CustomButton variant="primary" w="full" onClick={account ? handleDisconnect : onOpen}>
        {account ? 'Disconnect' : 'Connect'}
      </CustomButton>
      <Modal isOpen={isOpen} onClose={onClose} title="Connect Wallet">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
          {connectOptions.map(connectOption => (
            <Button flex={1} onClick={() => handleConnect(connectOption.type)} h="100%" py={6}>
              <Box>
                <Image src={connectOption.icon} h="100px" />
                <Box mt={4}>{connectOption.title}</Box>
              </Box>
            </Button>
          ))}
        </SimpleGrid>
      </Modal>
    </>
  )
}
