import React from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react'
import { Button } from 'components'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const SuccessModal = ({ isOpen, onClose }: Props) => {
  return (
    <ChakraModal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent position="relative" maxW="720px" minH="350px" borderRadius="xl">
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="2"
          borderRadius="xl"
          bgGradient="linear(rgba(0, 0, 0, .85), #FF5C0150)"
        />
        <Box
          bgColor="main.900"
          backgroundSize="cover"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="1"
          borderRadius="xl"
        />
        <Flex direction="column" align="center" my={8} zIndex="3">
          <ModalHeader
            fontSize={{ base: 'lg', sm: '2xl' }}
            textTransform="uppercase"
            color="secondary.600"
            textAlign="center"
          >
            Congratulations!
          </ModalHeader>
          <ModalCloseButton color="main.100" m={{ base: 0, sm: 0 }} fontSize="xs" />
          <ModalBody color="main.100" maxW="85%" textAlign="center">
            <Text>You have successfully started staking!</Text>
          </ModalBody>
          <ModalFooter zIndex="0" justifyContent="flex-start" mt={2}>
            <Flex w="100%" justifyContent="space-around" direction={{ base: 'column', sm: 'row' }}>
              <Button
                variant="primary"
                w={56}
                mx={2}
                mt={24}
                mb={{ base: 4, sm: 0 }}
                borderRadius="50px"
                fontSize="sm"
                fontWeight="bold"
              >
                CLOSE
              </Button>
            </Flex>
          </ModalFooter>
        </Flex>
      </ModalContent>
    </ChakraModal>
  )
}

export default SuccessModal
