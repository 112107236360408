import React from 'react'
import { Box, Flex, Text, Image } from '@chakra-ui/react'
import { LandInfo } from 'types/land'
import { ethers } from 'ethers'
import { getFormattedNumber } from 'utils/formatters'
import { LockButton, WithdrawButton } from 'components'

type Props = {
  stakeAmount: number
  reward: string
  rewardType: string
  landPool: LandInfo
  stakeTokens: (poolInfo: LandInfo, amount: number) => Promise<boolean>
  withdrawTokens: (poolInfo: LandInfo) => Promise<boolean>
  onStake: () => void
}

const LandCard = ({
  stakeAmount,
  reward,
  rewardType,
  landPool,
  stakeTokens,
  withdrawTokens,
  onStake,
}: Props) => {
  const landPoolProperties = [
    {
      label: 'Stake amount',
      value: getFormattedNumber(stakeAmount),
      imgPath: '/img/token/tokenIcon.png',
    },
    { label: 'Reward value', value: `${landPool.value} USD` },
    {
      label: 'You have staked',
      value: landPool.details?.accountStaked
        ? getFormattedNumber(parseFloat(ethers.utils.formatEther(landPool.details?.accountStaked)))
        : '-',
    },
    { label: 'APY', value: `${landPool.apy}%` },
  ]
  return (
    <Box className="wrapper" mx={2} my={{ base: 2, xl: 0 }}>
      <Flex
        w={350}
        h="auto"
        p={6}
        flexDirection="column"
        justifyContent="space-around"
        alignItems="stretch"
        color="main.100"
        bgColor="rgba(0, 0, 0, 0.80)"
        className="land-card"
        letterSpacing={1}
      >
        <Flex
          flexDirection="column"
          justifyContent="space-around"
          alignItems="flex-start"
          w="100%"
          pb={2}
        >
          <Flex
            w={12}
            h={12}
            bgColor="main.900"
            border="1px solid #A09389"
            borderRadius="50%"
            justifyContent="center"
            px={4}
            alignItems="center"
          >
            <Text color="main.100" fontSize="3xl" fontWeight="bold">
              {landPool.months}
            </Text>
          </Flex>
          <Text textTransform="uppercase" color="main.100" mt={2} fontSize="xs" fontWeight="bold">
            Months
          </Text>
          <Flex alignItems="center" mt={4}>
            {landPool.details?.totalSupply !== undefined && (
              <>
                <Text mr={2} color="secondary.600" fontSize="2xl" fontWeight="bold">
                  {landPool.details?.totalSupply}
                </Text>
                <Flex flexDirection="column">
                  <Text
                    textTransform="uppercase"
                    fontSize="0.6rem"
                    color="main.100"
                    fontWeight="bold"
                  >
                    Places
                  </Text>
                  <Text
                    textTransform="uppercase"
                    fontSize="0.6rem"
                    color="main.100"
                    fontWeight="bold"
                  >
                    Left
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>

        <Box w="100%" my={3}>
          <Flex justify="space-between" align="center" pb={2} position="relative">
            <Text
              textTransform="uppercase"
              color="main.100"
              fontWeight="bold"
              fontSize="xs"
              position="relative"
            >
              Rewards
            </Text>
            <Flex
              direction="column"
              position="absolute"
              right="0"
              bottom="2"
              fontSize="sm"
              fontWeight="bold"
              color="secondary.700"
            >
              <Text>{reward} &</Text>
              <Text>{rewardType} MOON Land</Text>
            </Flex>
          </Flex>
          {landPoolProperties.map(property => (
            <Flex justify="space-between" alignItems="center">
              <Text textTransform="uppercase" color="main.100" fontWeight="bold" fontSize="xs">
                {property.label}
              </Text>
              <Flex>
                {property.imgPath && <Image src={property.imgPath} mx={1} objectFit="contain" />}
                <Text fontWeight="bold" fontSize="2xl" color="main.100">
                  {property.value}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Box>
        <Flex position="relative" zIndex={1} w="100%">
          {landPool.details?.accountMaturityDate &&
          landPool.details.accountMaturityDate.getTime() < new Date().getTime() &&
          landPool.details.accountStaked !== '0' ? (
            <WithdrawButton pool={landPool} withdrawTokens={withdrawTokens} />
          ) : (
            <LockButton
              pool={landPool}
              amount={stakeAmount}
              stakeTokens={stakeTokens}
              onStake={onStake}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default LandCard
