import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Footer, Navbar } from 'components'
import { StakingForLands } from 'pages'
import { MenuData } from 'components/Navbar/types'
import { MENU_API_TOKEN } from 'data/config'

function App() {
  const [menuData, setMenuData] = useState<MenuData | null>(null)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://menu.moon.ws/api/getMenu?token=${MENU_API_TOKEN}`)
        const data = await response.json()
        setMenuData(data)
      } catch (error) {
        console.log('Error fetching menu data:', error)
      }
    }

    fetchData()
  }, [])
  return (
    <>
      <BrowserRouter>
        <Navbar menuData={menuData} />
        <Routes>
          <Route path="/" element={<StakingForLands />} />
        </Routes>
        <Footer menuData={menuData} />
      </BrowserRouter>
    </>
  )
}

export default App
