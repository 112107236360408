import React, { useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { networkConfigs } from 'data/networks'
import { LandInfo } from 'types/land'
import { useWallet } from 'providers/WalletProvider'
import { useTokenApproval } from 'hooks/useTokenApproval'
import { getFormattedNumber } from 'utils/formatters'
import { useTokenBalance } from 'hooks/useTokenBalance'
import { ethers } from 'ethers'
import { DEFAULT_CHAIN_ID } from 'data/config'
import { ConnectButton, Button, Modal, ModalErrorBody, ModalStakeBody } from 'components'

const NOT_ENOUGH_TOKENS_ERROR_MESSAGE = 'You do not have enough tokens'

type Props = {
  pool: LandInfo
  amount: number
  stakeTokens: (poolInfo: LandInfo, amount: number) => Promise<boolean>
  onStake: () => void
}

const LockButton = ({ pool, amount, stakeTokens, onStake }: Props) => {
  const { account, chainId } = useWallet()
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure()
  const [isStakeLoading, setIsStakeLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const {
    isApproved,
    isLoading: isApprovalLoading,
    approve,
  } = useTokenApproval({ account, spender: pool.address })

  const { balance } = useTokenBalance({ account, chainId })

  const isLoading = isStakeLoading || isApprovalLoading

  if (!account || chainId !== DEFAULT_CHAIN_ID || !pool.details) {
    return <ConnectButton />
  }

  if (pool.details.totalSupply === 0) {
    return (
      <Button variant="disabled" w="100%" h={12} py={4} zIndex={1} fontWeight="bold">
        Pool sold out
      </Button>
    )
  }

  if (!pool.details.isOpen) {
    return (
      <Button variant="disabled" w="100%" h={12} fontWeight="bold" py={4}>
        Staking is closed
      </Button>
    )
  }

  const handleLockClick = async () => {
    if (!account || !pool.details) {
      return
    }
    if (parseFloat(ethers.utils.formatEther(balance)) < pool.stakeAmount) {
      setErrorMessage(NOT_ENOUGH_TOKENS_ERROR_MESSAGE)
      openModal()
      return
    }

    if (amount === 0) {
      setErrorMessage('Please select a valid amount to lock')
      openModal()
      return
    }
    if (chainId && chainId !== pool.chainId) {
      setErrorMessage(
        `Please switch to ${networkConfigs[pool.chainId].networkName} network and try again`,
      )
      openModal()
      return
    }
    if (!pool.details.isOpen) {
      setErrorMessage('Pool is closed')
      openModal()
      return
    }

    setErrorMessage(null)
    openModal()
  }

  const handleApproveClick = async () => {
    if (!account || !pool.details) {
      return
    }
    setIsStakeLoading(true)
    const result = await approve()
    setIsStakeLoading(false)
    if (!result) {
      setErrorMessage('Failed to Approve')
    }
  }

  const handleStakeClick = async () => {
    if (!account || !pool.details) {
      return
    }
    setIsStakeLoading(true)
    const result = await stakeTokens(pool, amount)
    setIsStakeLoading(false)
    if (result) {
      closeModal()
      onStake()
    } else {
      setErrorMessage('Failed to Stake')
    }
  }

  return (
    <>
      <Button variant="primary" w="100%" fontWeight="bold" py={1} onClick={handleLockClick}>
        {`Stake: ${getFormattedNumber(amount)} $2MOON`}
      </Button>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={errorMessage ? 'Error' : 'Stake tokens'}
      >
        {!errorMessage ? (
          <ModalStakeBody
            amount={amount}
            tokenSymbol={pool.details.token.symbol}
            isApproved={isApproved}
            isLoading={isLoading}
            handleStakeClick={handleStakeClick}
            handleApproveClick={handleApproveClick}
          />
        ) : (
          <ModalErrorBody errorMessage={errorMessage} closeModal={closeModal} />
        )}
      </Modal>
    </>
  )
}

export default LockButton
