import React, { useState } from 'react'
import {
  Box,
  Flex,
  Image,
  Link,
  DrawerContent,
  Drawer,
  CloseButton,
  useDisclosure,
  IconButton,
  Text,
  chakra,
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronRightIcon, HamburgerIcon } from '@chakra-ui/icons'
import { Link as RouterLink } from 'react-router-dom'
import { ConnectButton, HoverMenu } from 'components'
import { Item, MenuData } from './types'
import { PAGE_MAX_WIDTH } from 'data/config'

type Props = {
  menuData: MenuData | null
}

export default function Navbar({ menuData }: Props) {
  const [selectedItem, setSelectedItem] = useState<Item>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onDrawerClose = () => {
    setSelectedItem(undefined)
    onClose()
  }

  return (
    <>
      <Flex
        justify="space-between"
        alignItems="center"
        w="100%"
        maxW={PAGE_MAX_WIDTH}
        h="35px"
        bgColor="main.900"
        display={{ base: 'none', lg: 'flex' }}
        borderBottom="0.5px solid"
        borderBottomColor="main.100"
        color="main.100"
        px={16}
        mx="auto"
        letterSpacing={1}
      >
        <Flex>
          {menuData?.crypto.map(crypto => (
            <Box mr={8}>
              <HoverMenu item={{ name: 'test', url: '#', children: crypto.links }}>
                {crypto.name} -{' '}
                <chakra.span color="secondary.600">{crypto.additional_info}</chakra.span>
              </HoverMenu>
            </Box>
          ))}
        </Flex>
        <Flex>
          {menuData?.social_links.map(social => (
            <Link href={social.url} target="_blank" _hover={{ textDecor: 'none' }}>
              <Flex mr={4}>
                <Box
                  key={social.name}
                  as="span"
                  dangerouslySetInnerHTML={{ __html: social.icon }}
                  color="main.100"
                  mr={2}
                />
                <Text color="secondary.600">{social.followers}</Text>
              </Flex>
            </Link>
          ))}
        </Flex>
      </Flex>
      <Flex
        position="relative"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        maxW={PAGE_MAX_WIDTH}
        h="auto"
        mx="auto"
        px={16}
        py={4}
        bgColor="black"
        display={{ base: 'none', lg: 'flex' }}
        letterSpacing={1}
      >
        <Flex justifyContent="center" alignItems="center" textTransform="uppercase">
          {menuData?.menu_items.map(link => (
            <>
              {link.children.length > 0 ? (
                <Box mr={4}>
                  <HoverMenu item={link}>{link.name}</HoverMenu>
                </Box>
              ) : (
                <Link
                  href={link.url}
                  display="inline-block"
                  mr={6}
                  _hover={{ textDecoration: 'none', color: 'secondary.600' }}
                >
                  <Text cursor="pointer">{link.name}</Text>
                </Link>
              )}
            </>
          ))}
        </Flex>
        <Flex position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" w={32}>
          <Image src="/img/moon/logo.png"></Image>
        </Flex>
        <Box mx={5} w={56}>
          <ConnectButton />
        </Box>
      </Flex>
      <Flex h={24} px={5} alignItems="center" display={{ base: 'flex', lg: 'none' }} bg="black">
        <Link flex={1} href="/" mr={6}>
          <Image height="30px" src="/img/moon/logo.png" />
        </Link>
        <IconButton
          icon={<HamburgerIcon />}
          onClick={onOpen}
          bg="transparent"
          aria-label="open menu"
        />
      </Flex>

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="top"
        onClose={onDrawerClose}
        returnFocusOnClose={false}
        onOverlayClick={onDrawerClose}
        size="full"
      >
        <DrawerContent bg="black">
          <Flex alignItems="center" py={6} px={6}>
            <Link flex={1} href="/">
              <Image height="30px" src="/img/moon/logo.png" />
            </Link>
            <CloseButton onClick={onDrawerClose} />
          </Flex>
          <Box>
            <Flex>
              <Flex
                direction="column"
                color="#fff"
                fontWeight="bold"
                fontSize="xl"
                textAlign="center"
                textTransform="uppercase"
                w="50%"
              >
                {menuData?.menu_items.map(link => (
                  <Flex
                    my={1}
                    py={4}
                    pl={4}
                    bgColor={selectedItem == link ? 'secondary.600' : 'main.900'}
                  >
                    {link.children.length > 0 && selectedItem != link && (
                      <ChevronDownIcon
                        my="auto"
                        onClick={() => setSelectedItem(link)}
                        cursor="pointer"
                        _hover={{ color: 'secondary.600' }}
                      />
                    )}
                    {link.children.length > 0 && selectedItem == link && (
                      <ChevronRightIcon
                        my="auto"
                        onClick={() => setSelectedItem(undefined)}
                        cursor="pointer"
                        _hover={{ color: 'secondary.600' }}
                      />
                    )}
                    <RouterLink to={link.url}>
                      <Text
                        mx={3}
                        _hover={{ textDecoration: 'none', color: '#fff' }}
                        textTransform="uppercase"
                      >
                        {link.name}
                      </Text>
                    </RouterLink>
                  </Flex>
                ))}
              </Flex>
              <Flex w="50%" direction="column">
                {selectedItem?.children.map(child => (
                  <Link
                    href={child.url}
                    alignSelf="flex-start"
                    my={4}
                    mx={2}
                    _hover={{ textDecoration: 'none' }}
                  >
                    <Text>{child.name}</Text>
                  </Link>
                ))}
              </Flex>
            </Flex>
            <Box
              key="button"
              py={6}
              px={4}
              fontWeight="bold"
              fontSize="xl"
              textAlign="center"
              textTransform="uppercase"
              w="50%"
            >
              <ConnectButton />
            </Box>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  )
}
