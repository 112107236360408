import React, { useState } from 'react'
import { Box, useDisclosure } from '@chakra-ui/react'
import { networkConfigs } from 'data/networks'
import { useWallet } from 'providers/WalletProvider'
import { Button, Loader, Modal, ModalErrorBody } from 'components'
import { LandInfo } from 'types/land'

type Props = {
  pool: LandInfo
  withdrawTokens: (poolInfo: LandInfo) => Promise<boolean>
}

const WithdrawButton = ({ pool, withdrawTokens }: Props) => {
  const { account, chainId } = useWallet()
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  if (!pool.details) {
    return null
  }

  const handleWithdrawClick = async () => {
    if (!account || !pool.details) {
      return
    }
    if (chainId && chainId !== pool.chainId) {
      setErrorMessage(
        `Please switch to ${networkConfigs[pool.chainId].networkName} network and try again`,
      )
      openModal()
      return
    }
    if (!pool.details.accountMaturityDate) {
      setErrorMessage('You have not staked in this pool')
      openModal()
      return
    }
    if (pool.details.accountMaturityDate.getTime() > Date.now()) {
      setErrorMessage('Maturity period is not over yet')
      openModal()
      return
    }
    setIsLoading(true)
    await withdrawTokens(pool)
    setIsLoading(false)
  }

  return (
    <Box w="100%">
      {!isLoading ? (
        <Button variant="primary" h="100%" fontWeight="bold" py={1} onClick={handleWithdrawClick}>
          Withdraw
        </Button>
      ) : (
        <Loader text="Waiting for transaction" spinnerSize="md" fontSize="md" />
      )}
      <Modal isOpen={isModalOpen} onClose={closeModal} title="">
        <ModalErrorBody errorMessage={errorMessage} closeModal={closeModal} />
      </Modal>
    </Box>
  )
}

export default WithdrawButton
