import React, { ReactNode } from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Box,
  Flex,
} from '@chakra-ui/react'

type Props = {
  children: ReactNode
  isOpen: boolean
  title: string
  onClose: () => void
}

const Modal = ({ isOpen, onClose, title, children }: Props) => {
  return (
    <ChakraModal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent position="relative" maxW="720px" minH="350px" borderRadius="xl">
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="2"
          borderRadius="xl"
          bgGradient="linear(rgba(0, 0, 0, .85), #FF5C0150)"
        />
        <Box
          bgColor="main.900"
          backgroundSize="cover"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="1"
          borderRadius="xl"
        />
        <Flex direction="column" align="center" my={8} zIndex="3">
          <ModalHeader
            fontSize={{ base: 'lg', sm: '2xl' }}
            textTransform="uppercase"
            color="secondary.600"
            textAlign="center"
          >
            {title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign="center" w="80%">
            {children}
          </ModalBody>
        </Flex>
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
