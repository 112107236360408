import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { primaryToken } from 'data/primaryToken'
import { useWallet } from 'providers/WalletProvider'
import { LandContractData, LandInfo } from 'types/land'
import { DEFAULT_CHAIN_ID } from 'data/config'

type Props = {
  poolsInfo: LandInfo[]
  account: string | null
  chainId: string | null
}

export const useLands = ({ poolsInfo, account, chainId }: Props) => {
  const [pools, setPools] = useState<LandInfo[]>(poolsInfo)
  const [isLoading, setIsLoading] = useState(false)
  const { readContract, writeContract } = useWallet()

  const getPoolAccountData = async (contractInfo, maturityDays: number) => {
    if (!account || chainId !== DEFAULT_CHAIN_ID) {
      return {
        accountStaked: null,
        accountMaturityDate: null,
      }
    }
    const accountStaked = await readContract({
      ...contractInfo,
      functionName: 'stakeOf',
      args: [account],
    })
    const accountStakedTime = await readContract({
      ...contractInfo,
      functionName: 'stakeTimeOf',
      args: [account],
    })
    const maturityDate =
      accountStakedTime > 0
        ? new Date(Number(accountStakedTime) * 1000 + maturityDays * 24 * 60 * 60 * 1000)
        : null
    return {
      accountStaked: `${accountStaked}`,
      accountMaturityDate: maturityDate,
    }
  }

  const getPoolContractData = async (poolInfo: LandInfo) => {
    const contractInfo = { address: poolInfo.address, abi: poolInfo.abi }
    const tokenAddress = await readContract({ ...contractInfo, functionName: 'tokenERC20' })
    if (!tokenAddress) {
      return null
    }
    const apy = await readContract({ ...contractInfo, functionName: 'poolApy' })
    const stakedTotal = await readContract({ ...contractInfo, functionName: 'stakedTotal' })
    const poolSize = await readContract({ ...contractInfo, functionName: 'poolSize' })
    const maturityDays = await readContract({ ...contractInfo, functionName: 'maturityDays' })
    const launchTime = await readContract({ ...contractInfo, functionName: 'launchTime' })
    const closingTime = await readContract({ ...contractInfo, functionName: 'closingTime' })
    const totalSupply = await readContract({ ...contractInfo, functionName: 'totalSupply' })

    const now = Date.now()
    const launchDate = new Date(parseFloat(launchTime) * 1000)
    const closingDate = new Date(parseFloat(closingTime) * 1000)
    const isOpen =
      now > launchDate.getTime() &&
      now < closingDate.getTime() &&
      parseFloat(poolSize) > parseFloat(stakedTotal)
    const poolData: LandContractData = {
      token: primaryToken,
      apy: parseFloat(apy),
      stakedTotal: `${stakedTotal}`,
      poolSize: `${poolSize}`,
      maturityDays: parseFloat(maturityDays),
      launchTime: parseFloat(launchTime),
      launchDate,
      closingTime: parseFloat(closingTime),
      closingDate,
      isOpen,
      accountStaked: null,
      accountMaturityDate: null,
      totalSupply: parseFloat(totalSupply),
    }
    if (account) {
      const { accountStaked, accountMaturityDate } = await getPoolAccountData(
        contractInfo,
        poolData.maturityDays,
      )
      poolData.accountStaked = accountStaked
      poolData.accountMaturityDate = accountMaturityDate
    }
    return poolData
  }

  const reload = async () => {
    setIsLoading(true)
    const poolsWithDetails = (
      await Promise.all(
        poolsInfo.map(async poolInfo => {
          if (poolInfo.address !== '') {
            const details = await getPoolContractData(poolInfo)
            return { ...poolInfo, details }
          }
          return poolInfo
        }),
      )
    ).filter(poolInfo => !!poolInfo)
    setPools(poolsWithDetails as any)
    setIsLoading(false)
  }

  const stakeTokens = async (poolInfo: LandInfo, amount: number) => {
    try {
      if (!poolInfo.details) {
        return false
      }
      const amountWei = ethers.utils.parseEther(`${amount}`)
      await writeContract({
        address: poolInfo.address,
        abi: poolInfo.abi,
        functionName: 'stake',
        args: [amountWei],
      })
      return true
    } catch (ex) {
      console.error(ex)
      return false
    }
  }

  const withdrawTokens = async (poolInfo: LandInfo) => {
    try {
      if (!poolInfo.details) {
        return false
      }
      await writeContract({
        address: poolInfo.address,
        abi: poolInfo.abi,
        functionName: 'withdraw',
      })
      await reload()
      return true
    } catch (ex) {
      console.error(ex)
      return false
    }
  }

  useEffect(() => {
    if (!account) {
      return
    }
    ;(async () => {
      await reload()
    })()
    // eslint-disable-next-line
  }, [account, chainId])

  return { pools, isLoading, reload, stakeTokens, withdrawTokens }
}
