import React, { createContext, useContext, useEffect, useState } from 'react'
import { useConnect, useDisconnect, usePublicClient, useWalletClient } from 'wagmi'

type WalletInfo = {
  account: string | null
  chainId: string | null
  readContract: (readProperties: {
    address: string
    abi: any
    functionName: string
    args?: any[]
  }) => any
  writeContract: (writeProperties: {
    address: string
    abi: any
    functionName: string
    args?: any[]
  }) => any
  connect: (type: string) => void
  disconnect: () => void
}

type Props = {
  children: any
}

const initialValue = {
  account: null,
  chainId: null,
  readContract: () => {},
  writeContract: () => {},
  connect: () => {},
  disconnect: () => {},
}

const WalletContext = createContext<WalletInfo>(initialValue)

export const useWallet = () => {
  return useContext(WalletContext)
}

const WalletProvider = ({ children }: Props) => {
  const { connectors, connect: wagmiConnect } = useConnect()
  const { data: walletClient } = useWalletClient()
  const publicClient = usePublicClient()
  const { disconnect: wagmiDisconnect } = useDisconnect()

  const [account, setAccount] = useState<string | null>(null)
  const [chainId, setChainId] = useState<string | null>(null)

  const connect = async (type: string) => {
    if (type === 'walletconnect') {
      wagmiConnect({ connector: connectors[0] })
      return
    }
    wagmiConnect({ connector: connectors[1] })
  }

  const disconnect = async () => {
    wagmiDisconnect()
  }

  const readContract = async (readProperties: {
    address: any
    abi: any
    functionName: string
    args?: any
  }) => {
    return publicClient?.readContract({
      ...readProperties,
      args: readProperties.args ?? [],
    }) as any
  }

  const writeContract = async (writeProperties: {
    address: any
    abi: any
    functionName: string
    args?: any[]
  }) => {
    const { request } = await publicClient.simulateContract({
      ...writeProperties,
      args: writeProperties.args ?? [],
      account: account as any,
    })
    const hash = await walletClient?.writeContract(request)
    if (hash) {
      const transaction = await publicClient.waitForTransactionReceipt({ hash })
      console.log(transaction)
    }
  }

  useEffect(() => {
    if (publicClient && walletClient) {
      setAccount(walletClient.account.address)
      setChainId(`0x${walletClient.chain.id.toString(16)}`)
    }
  }, [publicClient, walletClient])

  return (
    <WalletContext.Provider
      value={{ account, chainId, readContract, writeContract, connect, disconnect }}
    >
      {children}
    </WalletContext.Provider>
  )
}

export default WalletProvider
