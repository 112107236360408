import abi from 'data/abi/stakingPoolAirdrop.json'
import { LandInfo } from 'types/land'
import { DEFAULT_CHAIN_ID } from './config'

export const lands: LandInfo[] = [
  {
    address: '0x5619c975ECa80071F60c747368A86803b3e9204d',
    active: true,
    chainId: DEFAULT_CHAIN_ID,
    apy: 744,
    value: 279,
    rewardLands: 2,
    stakeAmount: 75000,
    months: 3,
    details: null,
    abi,
  },
  {
    address: '0x74FF604db01CC3E3D40F4E773bd151fC2f5bf36F',
    active: true,
    chainId: DEFAULT_CHAIN_ID,
    apy: 659,
    value: 659,
    rewardLands: 2,
    stakeAmount: 200000,
    months: 6,
    details: null,
    abi,
  },
  {
    address: '0xa146abf2cE25A5b62703980439214047b28540B0',
    active: true,
    chainId: DEFAULT_CHAIN_ID,
    apy: 580,
    value: 1449,
    rewardLands: 2,
    stakeAmount: 500000,
    months: 12,
    details: null,
    abi,
  },
]
